























































import NumberInput from 'library/components/global/number-input'
import ProductImageSlider from 'library/components/pages/product/product-image-slider'
import ProductRetailers from 'library/components/pages/product/product-retailers'
import StandardPurchase from 'library/components/global/product/purchasing/standard-purchase'
import SubscriberPurchase from 'library/components/global/product/purchasing/subscriber-purchase'
import VariantSelector from 'library/components/global/product/variant-selector'
import bundleVariantsMixin from '~/mixins/bundle-variants-mixin'
export default

	inject:
		customerHasSubscription: default: false

	mixins: [ bundleVariantsMixin ]

	props:
		product: Object

	components: {
		NumberInput
		ProductImageSlider
		ProductRetailers
		VariantSelector
	}

	data: ->
		productData: null
		currentVariant: @product.variants[0]
		quantity: 1
		adding: false
		offerState: null

	computed:

		images: -> @product.images
		variants: -> @product.variants
		available: -> @currentVariant.available

		productIsBundle: -> !!@product?.bundleVariants?.length

		onlyOnSmartCommerce: ->
			!@currentVariant.available and @product.tags.includes 'SmartCommerce_In_Stock'

		barcode: -> @currentVariant.upc

		customer: -> @$store.getters['customer/navigation/accessToken']

		# Make availability text
		availabilityText: ->
			if @currentVariant.available
			then @$t('product.add_to_cart') || 'Add To Cart'
			else @$t('product.sold_out') || 'Unavailable'

		# Which purchase options component to render. Force the SubscriberPurchase
		# UI to show if we know, from upstream, that the customer has a
		# subscription.
		purchaseComponent: -> switch
			when @customerHasSubscription then SubscriberPurchase
			when @subscribable then SubscriberPurchase
			else StandardPurchase

		# Check whether the current variant is subscribe-able
		subscribeable: ->

			# Check if customer is allowed to subscribe
			return false unless @$store.getters['customer/canPurchaseSubscriptions']

			# There should be no selling plan connections when non-subscribeable
			return false unless @currentVariant?.sellingPlanAllocations?.length

			# Also filter on name since, ATM, OG isn't clearing out the
			# sellingPlanAllocations connections when a product has no subscriptions.
			# Singles should never be subscribeable
			return false if @currentVariant?.name == 'Single'

			# Otherwise, assume variant is subscribebale
			return true

	watch:
		# Update the QS Modal if we switch to a bundled variant
		currentVariant: (variant) ->
			return unless !@productIsBundle and !!variant.isBundle
			@$emit('productChange', {id: variant?.id})

	methods:

		handleVariantClick: (variant) -> @currentVariant = variant

		# Handle upsell from OG widget
		upsell: ->
			@$emit("lineAdded", { variant: @currentVariant, quantity: @quantity })
			@$emit "close"

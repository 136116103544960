








































































import addDays from 'date-fns/addDays'
import startOfDay from 'date-fns/startOfDay'
import format from 'date-fns/format'
import { SUBSCRIPTION_DISCOUNT_PERCENTAGE } from 'library/services/ordergroove/constants'
import { ordergrooveApi } from 'library/services/ordergroove/helpers'

import { mountOnBody } from 'library/services/helpers'
import SubscriptionDetails from 'library/components/global/modals/subscription-details'

import SubscriptionRadio from './subscription-radio'
import SubscriptionFrequency from './subscription-frequency'
import AddToDeliveryModal from './add-to-delivery-modal'

export default

	name: 'productSubscriptionOptions'

	inject: defaultToSubscribe: default: false

	components: {
		SubscriptionRadio
		SubscriptionFrequency
	}

	props:
		variant: Object

		simpleUi: Boolean
		forceBoxUi: Boolean
		forceFrequency: Boolean
		forceSubscriptionRadios: Boolean
		showInfoIcon: Boolean

		purchaseText:
			type: String
			default: "Purchase <wbr>Now"

		subscribeText:
			type: String
			default: -> "Subscribe & <wbr>Save #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}%"

		addToSubscriptionText: String

		value: Object | Boolean

		purchaseType:
			type: String
			default: -> 'cart'

		onSuccess:
			type: Function
			default: () -> return

	data: ->
		purchaseTypeState: if @defaultToSubscribe then 'subscription' else @purchaseType
		selectedSellingPlan: @sellingPlan
		customerIsSubscribedToProduct: false
		loaded: false

	mounted: ->
		@$nextTick @updateData

	computed:
		loading: -> @loaded &&
			@$store.state.customer.navigation.hydrated

		addToSubscription: -> @purchaseTypeState == 'addToSubscription'

		available: -> @variant?.available

		isSubscribing: -> !!@value
		isSubscribable: -> @available && @variantSellingPlans?.length

		showFrequency: ->
			@isSubscribable &&
				!@hasSubscriptions &&
				(@forceFrequency || @isSubscribing)

		variantSellingPlans: -> @variant?.sellingPlanAllocations

		classes: -> [
			if @forceBoxUi then 'forced-box' else 'standard-box'
			if @simpleUi then 'simple'
		]

		unformattedDate: -> @$store.state.customer.navigation.unsentSubscriptions?[0]?.shipmentDate

		nextShipmentDate: ->
			return "loading" unless @unformattedDate
			format (startOfDay addDays new Date(@unformattedDate), 1), "MMMM dd"

		hasSubscriptions: -> @$store.state.customer.navigation.unsentSubscriptions?.length > 0

		_addToSubscriptionText: -> @addToSubscriptionText || "Add to your <b>#{@nextShipmentDate}</b> delivery and receive #{SUBSCRIPTION_DISCOUNT_PERCENTAGE}% off"

		customer: -> @$store.getters['customer/navigation/accessToken']

	methods:
		handleOpenSubscriptionInfoModal: (e) ->
			e.preventDefault()
			mountOnBody SubscriptionDetails, parent: this

		sellingPlanUpdate: -> @$emit('input', @purchaseTypeState == 'subscription' && @selectedSellingPlan)

		updateData: ->
			if @variant and @customer
				@customerIsSubscribedToProduct = await ordergrooveApi(
					'hasProductSubscription',
					@customer,
					{
						variantId: @$getShopifyId @variant.id
					}
				)

			if @purchaseTypeState == 'subscription' && @customer
				@purchaseTypeState = 'addToSubscription'
			@loaded = true

		openAddToDelivery: ->
			mountOnBody AddToDeliveryModal,
				parent: this
				propsData:
					handle: @variant?.title
					variantId: @$getShopifyId @variant.id
					sellingPlanAllocations: @variantSellingPlans
					initializer: @$refs.addToDelivery.$el
					onSuccess: @onSuccess


	watch:
		selectedSellingPlan: -> @sellingPlanUpdate()
		purchaseTypeState: (purchaseType) ->
			@$emit 'purchasetypeupdated', purchaseType
			@sellingPlanUpdate()
		variant: ->
			@loaded = false
			@updateData()

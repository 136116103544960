
# Export validator rules
export validators =
	email: (val) -> /\S+@\S+\.\S+/.test(val) || 'Not a valid email'
	required: (val) -> !!val.trim() || 'This field is required'

# Make a FormData instance given a data dictionary
export makeFormData = (data) ->
	formData = new FormData()
	for key, value of data
		formData.append key, value
	return formData

import { render, staticRenderFns } from "./block-list.vue?vue&type=template&id=7489d95c&scoped=true&lang=pug&"
import script from "./block-list.vue?vue&type=script&lang=coffee&"
export * from "./block-list.vue?vue&type=script&lang=coffee&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7489d95c",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {GlobalBlocksBlockWrapper: require('/opt/build/repo/nuxt-app/components/global/blocks/block-wrapper.vue').default})



























# Mapping of Craft block matrix _typename to component
export mapping =
	blocks_activityListing_BlockType: 'blocks-activity-listing'
	blocks_activitySegment_BlockType: 'blocks-activity-segment'
	blocks_athletesFilter_BlockType: 'blocks-athletes-filter'
	blocks_athletesListing_BlockType: 'blocks-athletes-listing'
	blocks_blockquote_BlockType: 'blocks-blockquote'
	blocks_byTheNumbers_BlockType: 'blocks-by-the-numbers'
	blocks_cardCarousel_BlockType: 'blocks-card-carousel'
	blocks_cardGrid_BlockType: 'blocks-card-grid'
	blocks_careersFilter_BlockType: 'blocks-careers-filter'
	blocks_collage_BlockType: 'blocks-collage'
	blocks_collectionTout_BlockType: 'blocks-collection-tout'
	blocks_colorTileCarousel_BlockType: 'blocks-color-tile-carousel'
	blocks_downloadsFilter_BlockType: 'blocks-downloads-filter'
	blocks_embed_BlockType: 'blocks-embed'
	blocks_eventsListing_BlockType: 'blocks-events-listing'
	blocks_familyOfBrands_BlockType: 'blocks-family-of-brands'
	blocks_faq_BlockType: 'blocks-faq'
	blocks_featuredIconCards_BlockType: 'blocks-featured-icon-cards'
	blocks_fullMarquee_BlockType: 'blocks-marquee-full'
	blocks_imageAndText_BlockType: 'blocks-image-and-text'
	blocks_imageCarousel_BlockType: 'blocks-image-carousel'
	blocks_inlineTouts_BlockType: 'blocks-inline-touts'
	blocks_largeDropdown_BlockType: 'blocks-large-dropdown'
	blocks_newsletter_BlockType: 'blocks-newsletter'
	blocks_newsletterV2_BlockType: 'blocks-newsletter-v2'
	blocks_nutritionNewsletter_BlockType: 'blocks-nutrition-newsletter'
	blocks_pressReleasesListing_BlockType: 'blocks-press-releases-listing'
	blocks_productCarousel_BlockType: 'blocks-product-carousel'
	blocks_searchBox_BlockType: 'library-global-search-box'
	blocks_socialLinks_BlockType: 'blocks-social-links'
	blocks_spacer_BlockType: 'library-global-spacer'
	blocks_splitMarquee_BlockType: 'blocks-marquee-split'
	blocks_standardMarquee_BlockType: 'blocks-marquee-standard'
	blocks_storiesListing_BlockType: 'blocks-stories-listing'
	blocks_subnav_BlockType: 'blocks-subnav'
	blocks_threeTwoOneUps_BlockType: 'blocks-three-two-one-ups'
	blocks_verticalScrollingSlider_BlockType: 'blocks-vertical-scrolling-slider'
	blocks_visualAsset_BlockType: 'blocks-visual-asset'
	blocks_whereToBuy_BlockType: 'blocks-where-to-buy'
	blocks_wysiwyg_BlockType: 'blocks-wysiwyg-block'
	blocks_wysiwygBanner_BlockType: 'blocks-wysiwyg-banner'

# Simplify reusable section refs
reusableSectionTypeName = 'blocks_reusableSection_BlockType'

# Hard import components components so they don't get chunked
import 'library/components/global/spacer'
import '~/components/global/blocks/block-wrapper'
import '~/components/blocks/wysiwyg-block'

# Component def for this block
export default
	name: 'BlockList'

	props:
		blocks: Array
		block: Object # Used by reusable sections

	computed:

		# Get the list of blocks from multiple props or default to an empty array
		blocksSource: -> getReusableSectionBlocks(@block) || @blocks || []

		# Map the list of blocks to support reusable section block list recursion
		blocksWithComponentNames: ->
			@blocksSource.map (block) -> {
				...block
				componentName: switch block.__typename
					when reusableSectionTypeName then 'BlockList'
					else mapping[block.__typename]
			}

		# Filter the blocks to those that have been defined.  To fix errors in dev
		# environments after content model is created but commits with the new
		# block component have not been pulled down.
		createdBlocks: ->
			typenames = Object.keys(mapping)
			@blocksWithComponentNames.filter (block) ->
				return true if block.componentName == 'BlockList'
				typenames.includes block.__typename

	methods:
		noGutter: (block) ->
			return true if block.__typename == reusableSectionTypeName
			return true if block.__typename == "blocks_featuredIconCards_BlockType"

# Get at the blocks from a reusable section
getReusableSectionBlocks = (block) -> block?.reusableSection?[0]?.blocks


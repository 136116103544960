













































import { VForm, VTextField } from 'vuetify/lib'
import { getCdilOrigin } from 'library/services/cde/cde-config'
import { makeFormData } from 'library/services/helpers'
export default

	components: {
		VForm
		VTextField
	}

	props:
		buttonTheme:
			type: String
			default: 'primary'

		inputTheme:
			type: String
			default: 'white'
			validator: (val) -> val in ['white', 'outline']

		size:
			type: String
			default: 'large'
			validator: (val) -> val in ['large', 'medium']

		eventId:
			type: String
			default: ''

		source:
			type: String
			default: ''

		buttonLabel:
			type: String
			default: 'text'
			validator: (val) -> val in ['text', 'arrow']

	data: ->
		valid: true
		submitting: false
		mustAgree: false
		recaptchaToken: null

		form:
			email: ''
			source: @source
			agree: false

		rules:
			email: [ @$validators.required, @$validators.email ]

		id: @getUid()

	computed:

		# Form submission payload
		payload: ->
			email: @form.email
			cdeSourceId: switch process.env.APP_ENV
				when 'prod' then '17c6b238-c32b-4ae1-89df-d542c95616af'
				when 'uat' then '6f9fa0d5-f669-4ac3-851e-51cb954c6608'
				else '38186999-9d44-41a0-8242-4a9e4913aa32'
			consentText: @agreeToTermsMsgText
			optIn: @form.agree
			'g-recaptcha-token': null

			# Historical, not used by CDE
			# source: @form.source
			# eventId: @eventId

		# Make the agree to terms message
		agreeToTermsMsg: -> """
			#{@agreeToTermsIntro}
			<a href='/privacy-statement'>#{@$t('newsletter.privacy_statement')}</a>
			and <a href='/use-terms'>#{@$t('newsletter.terms_of_use')}</a>.
		"""

		# Text only version of consent
		agreeToTermsMsgText: -> """
			#{@agreeToTermsIntro} #{@$t('newsletter.privacy_statement')} and
			#{@$t('newsletter.terms_of_use')}.
		"""

		# Switch the agree to terms opening message out depending on whether there
		# is a validation fail or not
		agreeToTermsIntro: ->
			unless @mustAgree
			then @$t('newsletter.i_have_read_and_agree_to_the')
			else @$t('newsletter.asterisk_you_must_agree_to_the')

		classes: -> [
			"input-theme-#{@inputTheme}"
			"size-#{@size}"
		]

	mounted: ->
		# If we know their email because they are logged in, use that
		@form.email = @$store.state.customer.email || ''

	methods:

		submit: ->
			# Require valid form
			return unless @$refs.form.validate()

			# Require agreeing to terms
			unless @form.agree then return @mustAgree = true

			# Prevent double submit
			return if @submitting
			@submitting = true

			# Submit the form
			try
				payload = {
					...@payload,
					'g-recaptcha-token': await @$getRecaptchaToken()
				}
				url = "#{getCdilOrigin()}/api/persona-form"
				await @$axios.post url, makeFormData payload
				@$emit 'update:state', 'success'

			catch error
				@$emit 'update:state', 'error'
				return console.error error.response?.data?.error || error

			finally
				@submitting = false

			# Show thank you message
			@step++

			# Persist customer email
			@$store.commit 'customer/setCustomer', email: @form.email
			dataLayer.push event: @$GTM_EVENTS.NEWSLETTER_SUBMIT

		reset: ->
			# Reset fields
			@form.email = ''
			@form.agree = false
			# Emit initial state to any listening parents
			@$emit 'update:state', 'init'

		getUid: (strLength=8) -> Math.random().toString(36).substr(2, strLength)
